<template>
  <v-form method="post" id="crawlRequestForm" @submit="register()" ref="saveNewPasswordForm">
    <v-text-field
      :hint="$t('hintPassword')"
      label="Password"
      color="primary"
      v-model="password"
      name="password"
      type="password"
      :rules="[rules.min, rules.required]"
      counter
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-text-field
      :label="$t('hintConfirmPassword')"
      color="primary"
      v-model="confirmedPassword"
      :rules="[passwordConfirmationRule]"
      name="password"
      type="password"
      @keypress.enter="resetNow()"
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-btn class="mt-2" color="primary" width="320px" :loading="buttonLoading" @keypress.enter="resetNow()" @click="resetNow()">
      {{ $t(`Save new password`) }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      name: 'ResetPassword',
      password: '',
      buttonLoading: false,
      confirmedPassword: '',
      rules: {
        required: value => !!value || 'This field is required.',
        min: v => v.length >= 8 || 'Min. 8 characters needed for a secure password.',
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.confirmedPassword || this.$t('errorPasswordDontMatch');
    },
  },
  methods: {
    async resetNow() {
      const isValid = this.$refs.saveNewPasswordForm.validate();
      if (!isValid) {
        return;
      }
      this.buttonLoading = true;
      const data = {
        password: this.password,
        confirmedPassword: this.confirmedPassword,
        resetToken: new URLSearchParams(window.location.search).get('resetToken'),
      };
      const resetResult = await this.$store.dispatch('user/saveNewPasswordByResetToken', data);
      if (resetResult) {
        this.$emit('message');
        this.password = '';
        this.confirmedPassword = '';
        this.$router.push({ name: 'AccountLoginRegister', query: { register: 'true' } });
      }
      this.buttonLoading = false;
    },
  },
};
</script>
