<template>
  <v-form method="post" id="crawlRequestForm" @submit.prevent="login()" ref="loginForm">
    <v-text-field
      :hint="$t('hintLoginEmail')"
      label="E-Mail"
      color="primary"
      :rules="[rules.required]"
      v-model="email"
      name="email"
      @keypress.enter="loginOrReset()"
      required
    >
    </v-text-field>
    <v-text-field
      :hint="$t('password')"
      :label="$t('password')"
      color="primary"
      v-model="password"
      :rules="[rules.passwordRequired]"
      name="password"
      type="password"
      @keypress.enter="loginOrReset()"
      required
      v-if="passwordReset === false"
    >
    </v-text-field>
    <v-btn
      class="mt-2 mb-3"
      color="primary"
      width="320px"
      :loading="buttonLoading"
      @click="loginOrReset()"
      @keypress.enter="loginOrReset()"
      >{{ textButton }}</v-btn
    >
    <a class="y-6" style="text-decoration: none" @click="passwordReset = !passwordReset">{{ textLinkReset }}</a>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      buttonLoading: false,
      password: '',
      passwordReset: false,
      rules: {
        required: value => !!value || 'Required.',
        passwordRequired: value => {
          if (this.passwordReset === true) {
            return true;
          }
          if (!value) {
            return 'Password is required.';
          }
          return true;
        },
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  computed: {
    textButton() {
      if (this.passwordReset === false) {
        return this.$t('Log in');
      }
      return this.$t('Reset Password');
    },
    textLinkReset() {
      if (this.passwordReset === false) {
        return this.$t(`Forgot password`);
      }
      return this.$t('I remember the password');
    },
  },
  methods: {
    async loginOrReset() {
      const isValid = this.$refs.loginForm.validate();
      if (!isValid) {
        return;
      }

      const { email } = this;
      if (this.passwordReset === true) {
        await this.$store.dispatch('user/resetPassword', { email });
        this.passwordReset = false;
        return;
      }

      const { password } = this;
      this.buttonLoading = true;
      const loginResult = await this.$store.dispatch('user/login', { email, password });
      if (loginResult) {
        this.$router.push({ name: 'ReportHistoryOverviewPrivate' });
      }
      this.buttonLoading = false;
    },
  },
};
</script>
