<template>
  <v-form method="post" id="crawlRequestForm" @submit="register()" ref="registerForm">
    <v-text-field
      :hint="$t('hintName')"
      label="Name"
      color="primary"
      v-model="name"
      name="name"
      :rules="[rules.required]"
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-text-field
      :hint="$t('hintEmail')"
      label="E-Mail"
      color="primary"
      v-model="email"
      name="email"
      :rules="[rules.email, rules.required]"
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-text-field
      :hint="$t('hintPassword')"
      label="Password"
      color="primary"
      v-model="password"
      name="password"
      type="password"
      :rules="[rules.min, rules.required]"
      counter
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-text-field
      :label="$t('hintConfirmPassword')"
      color="primary"
      v-model="confirmedPassword"
      :rules="[passwordConfirmationRule]"
      name="password"
      type="password"
      @keypress.enter="register()"
      required
      :disabled="buttonLoading"
    >
    </v-text-field>
    <v-row>
      <v-col cols="2" sm="2" md="2" lg="2" xl="2">
        <v-checkbox
          v-model="termsAccept"
          color="primary"
          value="termsAccept"
          :rules="[rules.required]"
          :disabled="buttonLoading"
          class="pl-2"
          @keypress.enter="register()"
        ></v-checkbox> </v-col
      ><v-col cols="10" sm="10" md="10" lg="10" xl="10">
        {{ $t('registerCheckboxAccept') }}
        <a target="_blank" :href="VUE_APP_URL_LEGAL_TOS"> {{ $t('tos') }} </a>,
        <a target="_blank" :href="VUE_APP_URL_LEGAL_PRIVACY"> {{ $t('dataprivacy') }} </a> and have read and understood the
        <a target="_blank" href="/about-cookie-scanner/">"about us"</a>
        section
        {{ $t('ofPage') }}
      </v-col>
    </v-row>
    <v-btn class="mt-2" color="primary" width="320px" :loading="buttonLoading" @keypress.enter="register()" @click="register()">
      Register
    </v-btn>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      buttonLoading: false,
      confirmedPassword: '',
      termsAccept: '',
      rules: {
        required: value => !!value || 'This field is required.',
        min: v => v.length >= 8 || 'Min. 8 characters needed for a secure password.',
        email: value => {
          const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

          if (value.match(validRegex) || value === '') {
            return true;
          }
          return 'It seems as if the email address is invalid. Please double check.';
        },
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.confirmedPassword || this.$t('errorPasswordDontMatch');
    },
    VUE_APP_URL_LEGAL_TOS: () => process.env.VUE_APP_URL_LEGAL_TOS,
    VUE_APP_URL_LEGAL_PRIVACY: () => process.env.VUE_APP_URL_LEGAL_PRIVACY,
  },
  methods: {
    async register() {
      const isValid = this.$refs.registerForm.validate();
      if (!isValid) {
        return;
      }
      this.buttonLoading = true;
      const data = {
        name: this.name,
        email: this.email,
        password: this.password,
        confirmedPassword: this.confirmedPassword,
        termsAccept: this.termsAccept,
      };
      const registerResult = await this.$store.dispatch('user/register', data);
      if (registerResult) {
        this.$emit('message');
        this.name = '';
        this.email = '';
        this.password = '';
        this.confirmedPassword = '';
        this.termsAccept = '';
      }
      this.buttonLoading = false;
    },
  },
};
</script>
