<template>
  <v-container>
    <v-container fluid ma-0 pa-0 fill-height justify-center>
      <v-card :flat="true" v-if="showResetPassword" width="320px" class="ma-10">
        <ResetPassword />
      </v-card>
      <v-card :flat="true" v-if="tab === 'tab-2' && !showResetPassword" width="320px" class="ma-10">
        <v-card-title class="headline d-flex justify-center">Create a new account</v-card-title>
        <v-card-text class="text-subtitle-1"
          >Create a <strong>free</strong> account to:
          <ul>
            <li>Get notified of any changes made to a website</li>
            <li>See the history of all your crawls.</li>
            <li>Crawl FIVE pages per crawl.</li>
            <li>Crawl the same domain multiple times per day</li>
            <li>Have a private history.</li>
            <li>Get paid option to crawl unlimited pages</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card :flat="true" :tile="false" width="320px" v-if="!showResetPassword">
        <v-tabs v-model="tab" centered grow icons-and-text class="mb-3" mobile-breakpoint="720">
          <v-tab key="1" href="#tab-1">
            Log in
            <v-icon>{{ mdiLoginVariant }}</v-icon>
          </v-tab>
          <v-tab key="2" href="#tab-2">
            Register
            <v-icon>{{ mdiAccountPlusOutline }}</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="1" value="tab-1">
            <v-card flat>
              <Login />
            </v-card>
          </v-tab-item>
          <v-tab-item key="2" value="tab-2">
            <v-card flat>
              <Register @message="changeTab" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-divider class="ma-4" />
        <div class="d-flex justify-center mb-6"></div>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiLoginVariant, mdiAccountPlusOutline } from '@mdi/js';
import { verifyUserEmail } from '../utils/apiLogin';
import Login from '../components/account/Login.vue';
import Register from '../components/account/Register.vue';
import ResetPassword from '../components/account/ResetPassword.vue';

export default {
  name: 'AccountLoginRegister',
  components: {
    Login,
    Register,
    ResetPassword,
  },
  data: () => ({
    tab: 'tab-1',
    mdiLoginVariant,
    mdiAccountPlusOutline,
    showResetPassword: new URLSearchParams(window.location.search).get('resetPassword') === 'true',
  }),
  async mounted() {
    if (this.verificationToken) {
      const result = await verifyUserEmail(this.verificationToken);
      if (!result.success) {
        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${result.data.message}`,
        });
        return;
      }
      this.$store.commit('message/setMessage', {
        color: 'success',
        message: this.$t('accountMessageMailVerifiedSuccess'),
      });
      this.$router.push({ name: 'AccountLoginRegister' });
    }
    if (this.tabRegister) {
      this.tab = 'tab-2';
    }
  },
  watch: {
    $route() {
      this.updateShowResetPasswordValue();
    },
  },
  computed: {
    verificationToken() {
      return new URLSearchParams(window.location.search).get('verification');
    },
    tabRegister() {
      return new URLSearchParams(window.location.search).get('register') === 'true';
    },
  },
  methods: {
    updateShowResetPasswordValue() {
      this.showResetPassword = new URLSearchParams(window.location.search).get('resetPassword') === 'true';
    },
    changeTab() {
      if (this.tab === 1 || this.tab === null) {
        this.tab = 2;
      }
      this.tab = 1;
    },
  },
  metaInfo: {
    title: 'Login - Free Cookie Scanner - cookie-scanner.com',
    decription:
      'Cookie scanner checks your website for a working cookie banner and potential privacy issues, like Cookies, third party requests and localStrorage.',
  },
};
</script>
